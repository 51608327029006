/*
  Note I leave this as an exampe how to override specificity if anybody want to override Ant components
  :global(:is(.ant-layout-header)).root
*/
.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  height: 3rem;
  padding-inline: 1rem;
  background-color: #001529;
}
