.root {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > span[role='img'] {
    font-size: 1.5rem;
  }

  > span[role='text'] {
    font-size: 0.875rem;
    font-weight: 400;
  }

  button {
    color: inherit;

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: hsl(0, 100%, 65%);
    }

    svg {
      font-size: 1.125rem;
    }
  }
}
