.root {
  position: relative;
  height: auto;
  color: black;
  filter: drop-shadow(0 2px 8px rgb(0 0 0 / 0.15));
  /* margin-right: 0.875rem; */

  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      max-height: 100dvh;
      position: sticky;
      top: 0;
    }
    /* .ant-layout-sider-trigger {
      color: inherit !important;
      background-color: inherit !important;
    } */
  }
}
.menu {
  &:first-of-type {
    flex: 1 1 auto;
  }

  > :global(li.ant-menu-item) {
    margin: 0;
    width: auto;
    padding-inline: 1rem;
  }
}
.arrow {
  position: absolute;
  left: calc(100% - 2px);
  top: 50%;
  translate: 0 -50%;
  cursor: pointer;
  & > path:nth-child(2) {
    rotate: 180deg;
    transform-origin: center;
    transition: rotate 0.25s;
  }

  &.collapsed > path:nth-child(2) {
    rotate: 0deg;
  }
}
