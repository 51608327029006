.main {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  color: white;

  img {
    height: 30px;
    width: auto;
  }
  b {
    font-size: 18px;
    font-weight: 600;
    line-height: 2rem;
    margin-top: 0.5rem; /* Note: This is a correction compared to the logo next to it */
  }
}

.login {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1.5rem;

  b {
    font-family: var(--font-inter-sans);
    font-size: 2rem;
    font-weight: 700;
    margin-top: 0.75rem; /* Note: This is a correction compared to the logo next to it */
  }
}
